import React, { useEffect } from "react";
import Home from "./pages/Home/Home";
import LoginPage from  './pages/AdminPanel/LoginPage/LoginPage'
import {Routes , Route , Navigate} from 'react-router-dom'
import AdminHomePage from './pages/AdminPanel/AdminHomePage/AdminHomePage'
import PrivateRoute from './utils/PrivateRoute'
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./firebase-config";
import { useDreamTourismContext} from "./context/DreamTourismContext";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import VisaSuccess from "./pages/AdminPanel/VisaServices/VisaSuccess";

function App() {
  const {setUser ,setIsLoged , isLoged , setTourPackages , visaSuccess , setVisaSuccess } = useDreamTourismContext()
  const pkgCollection = collection(db , 'packages') 
  const visaSuccessCollection = collection(db , 'visasuccess') 

  useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth , (user) => {
        if(user){
          setIsLoged(true)
          console.log(user.email)
          setUser(user.email)
        }else{
          setIsLoged(false)
        }
    })

    return unSubscribe
  },[setIsLoged , isLoged])

  useEffect(() => {
    const packageQ = query(pkgCollection, orderBy("creatDate" , "desc"))

    onSnapshot(packageQ , (snaps) => {
      setTourPackages(snaps.docs.map((doc) => ({...doc.data() , id:doc.id}) ))
    })    

    const visaSuccessQ = query(visaSuccessCollection, orderBy("creatDate" , "desc"))

    onSnapshot(visaSuccessQ , (snaps) => {
      setVisaSuccess(snaps.docs.map((doc) => ({...doc.data() , id:doc.id}) ))
    })    
  },[])

  return (
    <>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<LoginPage />} path="/admin/adminpanel/login" />
        
        <Route element={<PrivateRoute />}>
              <Route element={<AdminHomePage />} path="/admin/adminpanel/tourpackages" />
              <Route element={<VisaSuccess />} path="/admin/adminpanel/visa-success" />
        </Route>
        <Route path="*" element={<Navigate to="/admin/adminpanel/login" replace />} />
    </Routes>
    </>
  );
}

export default App;
