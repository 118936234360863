import React
 from 'react';
import {Swiper , SwiperSlide} from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import slider01 from '../../../assets/img/Slider/slider-01.jpg';
import slider02 from '../../../assets/img/Slider/slider-02.jpg';
import slider03 from '../../../assets/img/Slider/slider-03.jpg';


const Banner = () => {
  return (
    <div id="banner">
      <div id="slider-1" className="slider">
        <div className="swiper" id="swiper-1">
          <div id="hero" className="d-flex align-items-center">
            <div className="container" data-aos="zoom-out" data-aos-delay="100">
              <h1>Dream<span> Destination</span></h1>
              <h2>
                Welcome to Dream Destination Tourism. We specialize in all kinds of travel needs and make your travel easy and comfortable. Join us for a fabulous travel experience.
              </h2>
              <div className="d-flex">
                <a href="#about" className="btn-get-started scrollto">Get Started</a>
                <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video">
                  <i className="bi bi-play-circle"></i><span>Watch Video</span>
                </a>
              </div>
            </div>
          </div>
          <Swiper 
              modules={[Autoplay]}
              slidesPerView={1}
              autoplay
              pagination
          >
            <SwiperSlide className="swiper-slide">
              <img src={slider01} style={{ width: '100%', height: '80vh', objectFit: 'fill' }} alt="Slider 1" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <img src={slider02} style={{ width: '100%', height: '80vh', objectFit: 'fill' }} alt="Slider 2" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <img src={slider03} style={{ width: '100%', height: '80vh', objectFit: 'fill'}} alt="Slider 3" />
            </SwiperSlide>
          </Swiper>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
