import React, { useState } from 'react'
import './PreLoader.css'
import { Circles } from  'react-loader-spinner'
import { motion } from 'framer-motion';

function PreLoader() {
  const [welcome , setWelcome] = useState([ ...'Dream', ' ', ...'Destination' , ' ' , ...'Tourism'])

  console.log(welcome);


  return (
    <div className='loader-page-home' >
            <span className='red-blur' ></span>
            <span className='orange-blur' ></span>
            <div className="loader-welcome">
                <Circles
                visible={true}
                height="120"
                width="120"
                color="#dc1a22"
                secondaryColor='#dc1a22'
                ariaLabel="tail-spin-loading"
                radius="3"
                wrapperStyle={{}}
                wrapperClass=""
                />
                <div>
                 <h3>
                       {
                        welcome?.map((eachChr , index) => (
                          <motion.span
                          key={`welcome-char-${index}`}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: [0.57, 0.21, 0.69, 1.25],
                    delay: 0.2 * index,
                           }}
                          >
                            {eachChr}
                          </motion.span>
                        ))
                       }
                 </h3>
                </div>
            </div>
            
    </div>
  )
}

export default PreLoader